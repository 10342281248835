<template>
  <v-data-table
  @click:row="viewReport($event)"
  class="elevation-4 rounded-lg pb-2"
  disable-sort
  :headers="headers"
  hide-default-footer
  :items="recentScans">
    <template v-slot:top>
      <v-toolbar dense flat class="rounded-lg">
        <!-- <v-toolbar-title class="text-h5 pt-2">Reports for Popular Extensions</v-toolbar-title> GO BACK TO THIS WHEN WE CAN-->
        <v-toolbar-title class="text-h5 pt-2">Recently Scanned Extensions</v-toolbar-title>
      </v-toolbar>
    </template>
    <template v-slot:item.extension="{ item }">
      <v-col class="py-1 text-subtitle-1">
        <v-row align="center" justify="start" class="flex-nowrap">
          <div style="height: 48px; width: 48px;" class="mr-2">
            <img style="position: absolute;" height="42px" width="42px" :src="item.data.webstore.icon"/>
            <img style="position: absolute; margin-left: 30px; margin-top: 30px;" height="18px" width="18px" :src="logos[item.platform]"/>
          </div>
          {{ item.data.webstore.name }}
        </v-row>
      </v-col>
    </template>
    <template v-slot:item.data.risk.total="{ item }">
      <v-col class="py-0 text-subtitle-1">
        <v-row align="center" justify="end">
          {{ item.data.risk.total }}
        </v-row>
      </v-col>
    </template>
    <template v-slot:item.view="{ item }">
      <v-tooltip top transition="fade-transition">
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" icon><v-icon>description</v-icon></v-btn>
        </template>
        <span>View Full Report</span>
      </v-tooltip>
    </template>
  </v-data-table>
</template>
<script>
import { http } from '@/plugins/axios'

export default {
  data () {
    return {
      recentScans: [],  // Default to empty array
      loading: true,    // Add a loading state
      headers: [
        { text: 'Extension', value: 'extension' },
        { text: 'Risk Score', value: 'data.risk.total', align: 'end' },
        { text: 'View', value: 'view', align: 'center' }
      ],
      logos: {
        'Chrome': require('@/assets/images/chrome_logo_small.png'),
        'Firefox': require('@/assets/images/firefox_logo_small.png'),
        'Edge': require('@/assets/images/edge_logo_small.png')
      }
    }
  },
  methods: {
    getRecentScans () {
      this.loading = true;
      http.get('/scans')
        .then(response => {
          if (Array.isArray(response.data)) {
            this.recentScans = response.data;
          } else {
            console.warn('Expected an array, but received:', response.data);
            this.recentScans = [];  // Fallback to empty array
          }
        })
        .catch(error => {
          console.log(error);
          this.recentScans = [];  // Fallback to empty array
        })
        .finally(() => {
          this.loading = false;  // Set loading to false when done
        });
    },
    viewReport (item) {
      this.$router.push({ path: '/report/' + item.extension_id + '/' + item.version + (item.platform ? ('?platform=' + item.platform) : '') })
    }
  },
  mounted () {
    this.getRecentScans();
  }
}

</script>
